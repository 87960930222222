import React from "react"

const MWC2025 = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        marginLeft: "-10px",
        marginTop: "-10px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <iframe
        src="/documents/mwc_catalogue_2025_v2.pdf"
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="MWC 2025 Catalogue"
      />
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          background: "rgba(255, 255, 255, 0.8)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <a
          href="/documents/mwc_catalogue_2025_v2.pdf"

          download
          style={{
            textDecoration: "none",
            color: "#333",
            fontWeight: "bold",
          }}
        >
          Download PDF
        </a>
      </div>
    </div>
  )
}

export default MWC2025
